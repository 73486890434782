// Async variable names.
export const ACCESS_TOKEN = "AAI_ACCESS_TOKEN";
export const REFRESH_TOKEN = "AAI_REFRESH_TOKEN";
export const AES_KEY = "AAI_AES_KEY";
export const ZvalueMultiplyby = 10;
export const ARRIVAL_AIRPORT_RADIUS = 277.8; // KM

export const defaultLocationCenter = {
  lat: 20.5937,
  lng: 78.9629,
};

// Google Map]
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GMAP_KEY;
export const mapStyleWithoutLabel = [
  {
    featureType: "all",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const mapStyles = {
  roadmap: "roadmap",
  satellite: "satellite",
  terrain: "terrain",
  hybrid: "hybrid",
  osm: "osm",
  esriRoad: "esriroadmap",
  esriSatellite: "esrisatellite",
};

export const roles = {
  admin: "ADMIN",
  surveyor: "SURVEYOR",
  aai: "AAI_USER",
};

export const userType = {
  surveyor: "SURVEYOR",
  aai: "AAI_USER",
};

export const userLabel = {
  SURVEYOR: "Surveyor",
  AAI_USER: "AAI User",
};

export const amdbAirportList = [
  "CHENNAI INTERNATIONAL AIRPORT",
  "NETAJI SUBHASH CHANDRA BOSE INTERNATIONAL AIRPORT, KOLKATA",
  "TIRUCHIRAPPALLI AIRPORT",
  "IMPHAL AIRPORT",
  "CALICUT AIRPORT",
];

export const eChartAirportList = [
  "CHENNAI INTERNATIONAL AIRPORT",
  "NETAJI SUBHASH CHANDRA BOSE INTERNATIONAL AIRPORT, KOLKATA",
  "TIRUCHIRAPPALLI AIRPORT",
  "IMPHAL AIRPORT",
  "CALICUT AIRPORT",
];

export const mapDrawType = {
  polygon: "Polygon",
  lineString: "LineString",
  point: "Point",
  polyline: "Polyline",
};
