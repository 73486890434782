import React, { Fragment, useMemo } from "react";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import MapLayers from "./map/MapLayers";
import ControlMapCenter from "./map/ControlMapCenter";
import FullScreenControl from "./map/FullScreenControl";

export default function AixmMap({
  classname = "",
  airportLocation,
  zoomLevel,
  selctedAirport,
}) {
  const aixmMapE = useMemo(() => {
    return (
      <MapContainer
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
        className={classname}
      >
        <ControlMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
        <ZoomControl position="bottomright" />
        <FullScreenControl />
        <MapLayers />
        <ScaleControl position="bottomleft" />
      </MapContainer>
    );
  }, [airportLocation, zoomLevel, selctedAirport]); // eslint-disable-line

  return <Fragment>{aixmMapE}</Fragment>;
}
